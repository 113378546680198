import React from "react";
import { useParams } from "react-router-dom";
import HelmetTags from "../../../MainComponents/HelmetTags";
import useAxios from "../../../MainComponents/Hooks/useAxios";

export default function StudentLink() {
  const { courseID, linkID } = useParams();
  const [getData, getErrors, getLoading] = useAxios(
    `${process.env.REACT_APP_STUDENT_LECTURE_MATERIAL_LINK_API}${linkID}/${courseID}`,
    "GET",
    "GET",
    ""
  );

  return (
    <section className=" width  mb-32 h-full  dark:bg-dark dark:text-light flex items-center justify-center">
      <HelmetTags title="Link | Nash Academy"></HelmetTags>
      <div className="flex flex-col gap-5 max-w-[600px] w-full rounded-2xl shadow-2xl px-3 py-3 ">
        <p className="text-secondary text-3xl">{getData?.name}</p>
        <p className="text-secondary text-3xl">
          {getData?.password ? `Password : ${getData?.password}` : ``}
        </p>
        <a
          className="bg-secondary px-2 py-3 active:scale-95 text-3xl rounded-2xl text-white w-fit mx-auto"
          href={getData?.link}
          target="_blank"
        >
          Open Link
        </a>
      </div>
    </section>
  );
}
